/*!
* Bootstrap v5.0.0-alpha (https://getbootstrap.com/)
* Copyright 2011-2020 The Bootstrap Authors
* Copyright 2011-2020 Twitter, Inc.
* Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
*/

/*! Theme created with the Bootstrap Shuffle (https://bootstrapshuffle.com) */

// Required
@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/mixins';
// Your variables
@import 'custom-variables';
// Required Bootstrap variables
@import '../../node_modules/bootstrap/scss/variables';
// Utilities
@import '../../node_modules/bootstrap/scss/utilities';

// Layout & components 
@import '../../node_modules/bootstrap/scss/root';
@import '../../node_modules/bootstrap/scss/reboot';
@import '../../node_modules/bootstrap/scss/type';
@import '../../node_modules/bootstrap/scss/images';
@import '../../node_modules/bootstrap/scss/containers';
@import '../../node_modules/bootstrap/scss/grid';
@import '../../node_modules/bootstrap/scss/tables';
@import '../../node_modules/bootstrap/scss/forms';
@import '../../node_modules/bootstrap/scss/buttons';
@import '../../node_modules/bootstrap/scss/transitions';
@import '../../node_modules/bootstrap/scss/dropdown';
@import '../../node_modules/bootstrap/scss/button-group';
@import '../../node_modules/bootstrap/scss/nav';
@import '../../node_modules/bootstrap/scss/navbar';
@import '../../node_modules/bootstrap/scss/card';
@import '../../node_modules/bootstrap/scss/accordion';
@import '../../node_modules/bootstrap/scss/breadcrumb';
@import '../../node_modules/bootstrap/scss/pagination';
@import '../../node_modules/bootstrap/scss/badge';
@import '../../node_modules/bootstrap/scss/alert';
@import '../../node_modules/bootstrap/scss/progress';
@import '../../node_modules/bootstrap/scss/list-group';
@import '../../node_modules/bootstrap/scss/close';
@import '../../node_modules/bootstrap/scss/toasts';
@import '../../node_modules/bootstrap/scss/modal';
@import '../../node_modules/bootstrap/scss/tooltip';
@import '../../node_modules/bootstrap/scss/popover';
@import '../../node_modules/bootstrap/scss/carousel';
@import '../../node_modules/bootstrap/scss/spinners';

// Helpers
@import '../../node_modules/bootstrap/scss/helpers';

// Utilities
@import '../../node_modules/bootstrap/scss/utilities/api';
// Your styles
@import 'custom-styles';